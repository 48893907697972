<template>
  <div class="down">
    <div class="content">
      <div class="left">
        <div class="title">堆云医生 扫码下载</div>
        <div class="bg">
          <div class="section_8 flex-col">
            <div class="image-wrapper_4 flex-col">
              <img class="image_6" referrerpolicy="no-referrer" src="@/assets/shouye/logo.png" />
              <img class="image_7" referrerpolicy="no-referrer" src="@/assets/shouye/bianzu14.png" />
            </div>
            <div class="text_18">扫码下载客户端</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">堆云健康 扫码关注</div>
        <div class="bg">
          <div class="section_8 flex-col">
            <div class="image-wrapper_4 flex-col">
              <img class="image_6" referrerpolicy="no-referrer" src="@/assets/shouye/logo.png" />
              <img class="image_7" referrerpolicy="no-referrer" src="@/assets/shouye/bianzu15.png" />
            </div>
            <div class="text_19">用户扫码关注堆云健康</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'List',
  props: ['data'],
  methods: {},
  computed: {}
}
</script>

<style scoped lang="scss">
.down {
  background: url(../../assets/shouye/bianzu9.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 962px;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 873px;
    margin: auto;
    padding-top: 171px;
    .title {
      font-size: 42px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 56px;
      margin-bottom: 37px;
      font-weight: bold;
    }
    .bg {
      margin-left: 42px;
      background: url(../../assets/shouye/bianzu10.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 303px;
      height: 567px;
      // padding-top: 210px;

      .image-wrapper_4 {
        background-color: rgba(255, 255, 255, 1);
        height: 139px;
        width: 132px;
        position: relative;
        margin: 101px 0 0 58px;
      }

      .image_6 {
        width: 135px;
        height: 32px;
        margin: 11px 0 0 -1px;
      }

      .image_7 {
        position: absolute;
        left: 17px;
        top: 109px;
        width: 102px;
        height: 102px;
      }

      .text_18 {
        width: 112px;
        height: 22px;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 16px;
        font-family: PingFangSC-Regular;
        font-weight: NaN;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
        margin: 84px 0 221px 67px;
      }
      .text_19 {
        width: 160px;
        height: 22px;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 16px;
        font-family: PingFangSC-Regular;
        font-weight: NaN;
        text-align: left;
        white-space: nowrap;
        line-height: 22px;
        margin: 84px 0 221px 44px;
      }
      .flex-col {
        display: flex;
        flex-direction: column;
      }
      .code {
        margin-left: 100px;
        // margin-top: 210px;
        width: 102px;
        height: 102px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .code1 {
        background-image: url(../../assets/shouye/bianzu14.png);
      }
      .code2 {
        background-image: url(../../assets/shouye/bianzu15.png);
      }
      .bgtitle {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        // margin-top: 324px;
      }
    }
  }
}
</style>
