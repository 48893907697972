<template>
  <div class="home">
    <div class="up">
      <div class="upleft">
        <span>关于我们</span>
        <span>About us</span>
        <span
          >堆云隶属于北京如云欣达科技有限公司，是国内领先的数字互联网医疗平台,主要包括堆云健康和堆云医生等业务模块，服务内容包括防脱,养护，药物、植发和假发五大板块，旨在为患者提供一站式的医疗服务。<br /><br />

          目前平台现拥有专业的运营团队和丰富的临床经验的医生群体，提供24小时全天候的远程医疗服务，患者可以通过堆云健康微信公众号了解自己感兴趣的医生，并直接预约相应科室实现医生在线咨询、评估、诊疗、护理、保健、科教等服务，在最短的时间内获得最新的医学资讯和就医建议。医生则可通过堆云医生app相互学习，提升自己的专业能力，增加自己的影响力及知名度，赢得得更多的患者认可与信任。<br /><br />

          未来，堆云将继续以“互联网+医疗”为基础，整合医生资源，发挥供应链优势，打造贯穿线上线下一体化的智慧解决方案，成为国内领先的一站式*医学*管理中心方案和服务提供商。</span
        >
      </div>
      <div class="upright"></div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Foot from '@/components/Foot'

export default {
  name: 'Home',
  components: {
    Foot
  },
  data() {
    return {
      isHome: true
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  .up {
    width: 100%;
    background: url(../../assets/shouye/bianzu11.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 900px;
    position: relative;
    .upright {
      background: url(../../assets/shouye/bianzu12.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      width: 401px;
      height: 753px;
      right: 12%;
      top: 98px;
    }
    .upleft {
      padding-top: 110px;
      width: 46.5%;
      margin-left: 12.5%;
      span {
        display: block;
        &:nth-child(1) {
          // margin-top: 111px;
          font-size: 48px;
          font-family: MicrosoftYaHei;
          color: #333333;
          font-weight: 550;
        }
        &:nth-child(2) {
          font-size: 20px;
          font-family: MicrosoftSansSerif;
          color: #999999;
          margin-bottom: 67px;
        }
        &:nth-child(3) {
          max-width: 894px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 32px;
        }
      }
    }
  }

  .dt {
    width: 75%;
    margin: auto;
    padding: 89px 0 84px 0;
    .dttitle {
      margin-bottom: 65px;
      div:first-child {
        font-size: 48px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 64px;
      }
      div:last-child {
        font-size: 20px;
        font-family: MicrosoftSansSerif;
        color: #999999;
        line-height: 22px;
      }
    }
    .more {
      width: 174px;
      margin: auto;
      button {
        border: 2px solid #31ab77;
        font-size: 18px;
        font-family: MicrosoftYaHei;
        color: #31ab77;
        line-height: 24px;
        background: white;
      }
      button:hover {
        background: #31ab77;
        color: white;
      }
    }
  }

  // .down {
  //   background: url(../../assets/shouye/bianzu8.png);
  //   background-repeat: no-repeat;
  //   background-size: 100% 100%;
  //   height: 962px;
  // }
}
</style>
